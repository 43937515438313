.bc-image-anchor {
	position: relative;
	overflow: hidden;
}

.bc-villa-image {
	position: absolute;
	width: 100%;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.content-container.bc-body {
	padding-top: 0;
	padding-bottom: 0;
}

.bc-card.card {
	width: 100%;
	max-width: 509px;
	margin: 24px 0px;
	padding: 0;
}

.bc-card-body.card-body {
	padding: 36px;
}

.bc-dates {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	margin-bottom: 27px;
}

.bc-dates-arrow {
	margin: 0 12px;
	font-size: 1.1rem;
}

.bc-dates-date {
	font-size: 1.1875rem;
	font-weight: 600;
}

.bc-dates-time {
	font-weight: 300;
}

.bc-booking-info {
	margin-bottom: 50px;
}

.bc-booking-info {
	font-weight: 400;
}

.bc-villa-name {
	font-size: 1.75rem;
	font-weight: 700;
}

.bc-pb-row {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 1.1875rem;
}

.bc-total-price {
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.bc-tp-title {
	font-weight: 600;
}

.bc-pb-price {
	font-weight: 600;
	white-space: nowrap;
}

.bc-tp-view-breakdown {
	font-size: 1.0625rem;
	color: #006da8;
	cursor: pointer;
	margin-left: 7px;
}

.bc-blue-line {
	width: 100%;
	height: 1px;
	border-top: 1px solid #96d1f2;
}

.bc-pb-due-date {
	font-size: 1rem;
	font-weight: 300;
}

.bc-dd-signposting {
	display: flex;
	margin-top: 18px;
	justify-content: flex-start;
	align-items: center;
}

.bc-set-dd-btn {
	margin-right: 30px;
}

.confirmation-price-breakdown-wrap {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	background: rgba(0,0,0,0.5);
	z-index: 1000;
}

.confirmation-price-breakdown {
	width: 90%;
	max-width: 500px;
	position: relative;
}

.cpb-close {
	width: 40px;
	position: absolute;
	top: 5px;
	right: 5px;
	cursor: pointer;
}

@media (min-width: 576px) {
	.bc-body-wrap {
		padding: 30px 0;
	}
}

@media (max-width: 820px) and (min-width: 576px) {
	.bc-villa-image {
		width: auto;
		height: 100%;
	}
}

@media (max-width: 575px) {
	.bc-image-anchor {
		overflow: visible;
		width: 100%;
	}

	.bc-image-wrap {
		position: relative;
		height: 168px;
		overflow: hidden;
	}

	.bc-card.card {
		margin: -7px 0 10px;
		max-width: none;
	}

	.bc-card-body.card-body {
		padding: 19px;
	}

	.bc-dates {
		margin-bottom: 20px;
	}

	.bc-dates-date, .bc-pb-row {
		font-size: 1.125rem;
	}

	.bc-dates-time, .bc-tp-view-breakdown {
		font-size: 1rem;
	}

	.bc-booking-info {
		margin-bottom: 38px;
	}
}

@media (max-width: 400px) {
	.content-container.bc-body {
		padding: 0 7px;
	}
}