#airportSelectorModal {
    position: absolute;
    top: -10px;
    left: 2.25rem;
    width: 305px;
    padding: 20px 30px 22px 30px;
    border-radius: 10px;
    box-shadow: 0 1px 3px 0 rgba(60, 64, 67, 0.13), 0 0 8px 3px rgba(60, 64, 67, 0.07);
    background-color: #fff;
    z-index: 2;
}
.airportSelectorDiv {
    width: fit-content;
}
#airportSelector {
    justify-content: space-between;
    padding: 8px 13px 14px 18px;
    border-radius: 26px;
    border: solid 1px #5e5e5e;
    background-color: #fff;
    width: 290px;
    height: 52px;
}
    #airportSelector > .airportName {
        font-size: 19px;
        font-weight: normal;
        margin-left: 20px;
        margin-top: 2px;
    }
.airport-placeholder-wrapper {
    margin-left: 47px;
    padding: 0px 10px;
    pointer-events: none;
    position: absolute;
    left: 0;
    top: -13px;
    transition: 0.2s;
    transition-timing-function: ease;
    transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
    opacity: 1;
    color: #615e5e;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    background-color: #fff;
}
.airportIcon{
    vertical-align: unset !important;
}
.airportSelectorModalTitle {
    font-size: 19px;
    font-weight: 600;
}
.activeAirport {
    border-radius: 19px;
    background-color: #C9E7F9;
    font-weight: 600 !important;
}

.selectorItem {
    height: 40px;
    cursor: pointer;
    font-size: 17px;
    font-weight: 300;
}
.caretDownIcon{
    right:0;
    margin-top:3px;
}
@media (max-width: 767px) {
    #airportSelectorModal {
        left: 1rem;
    }
}
@media (max-width: 576px) {
    .airportSelectorDiv {
        width: unset;
    }
    #airportSelector {
        width: auto !important;
    }

    #airportSelectorModal {
        width: 80% !important;
    }
}
