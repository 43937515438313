#privateTransfersCard {
    padding: 1rem 0;
}
#privateTransfersCard .card-body {
    padding: 1.25rem 0;
}
#privateTransfersCard .card-title, .airportSelectorDiv {
    padding: 0 2.25rem;
}
    #privateTransfersCard .privateTransfersSlider {
        padding: 0 2.25rem;
    }
    #privateTransfersCard .skeleton {
        padding: 0 2.25rem;
    }
.transfer-card {
    text-align: center;
    padding: 20px 20px 20px 20px;
    /*border-right: solid 1px #ededed;*/
}
.transfer-card-selected {
    border-radius: 10px;
    /*border: solid 2px #2d4a60;*/
    /*background-color: rgba(150, 209, 242, 0.08);*/
    background-color: #c9e7f9;
}
.transfer-img {
    width: 80%;
    max-width:180px;
    height: auto;
}
.cardLabel {
    text-align: left !important;
}
.transfer-card .checkmark {
    position: relative !important;
    left: 0 !important;
}
.swiper-wrapper {
    margin-bottom: 30px;
}
.swiper-horizontal > .swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
    bottom: 0px !important;
}
.swiper-pagination-bullet {
    border: solid 1px #d0cfcf !important;
    background: #fff !important;
    opacity:1 !important;
}
.swiper-pagination-bullet-active {
    background: #006fad !important;
}
.swiper-button-next, .swiper-button-prev {
    background-image: url(../../../images/prev-arrow-border.svg) !important;
    background-repeat: no-repeat !important;
    background-size: 100% auto !important;
    background-position: center !important;
    width: calc(var(--swiper-navigation-size)/ 44 * 60) !important;
    outline: none !important;
    top: 70% !important;
    border: solid 1px #fff;
}
.swiper-button-next {
    transform: rotate(180deg);
    right:0px !important;
}
.swiper-button-prev {
    left: 0px !important;
}
    .swiper-button-next:after, .swiper-button-prev:after {
        content: '' !important;
    }

@media (max-width: 992px) and (min-width: 768px) {
    .cardLabel {
        text-align: center !important;
    }

    .transfer-card .checkbox-container {
        justify-content:center;
    }
}
@media (max-width: 767px) {
    #privateTransfersCard .card-body {
        padding: 0.5rem 0;
    }

    #privateTransfersCard .card-title, .airportSelectorDiv {
        padding: 0 1rem;
    }

    #privateTransfersCard .privateTransfersSlider {
        padding: 0 1rem;
    }

    #privateTransfersCard .skeleton {
        padding: 0 1rem;
    }
}
@media (max-width: 576px) {
    .cardLabel {
        text-align: center !important;
    }

    .transfer-card .checkbox-container {
        justify-content: center;
    }
}


.add-button {
    border: solid 1px #303c55;
    font-family: Domus;
    font-size: 16px;
    font-weight: 600;
    padding: 7px 23px;
    border-radius: 10px;
    border: solid 1px #222B3F;
    background-color: #fff;
    height: 42px;
}

.added-button {
    background-color: #c9e7f9;
    border-radius: 10px;
    border: solid 1px #222B3F;
    font-family: Domus;
    /*color: #fff;*/
    font-size: 16px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 42px;
    padding: 8px 12px 8px 12px;
}

/*.added-button::before {
    content: "";
    background: url('../../../images/icon-check-white.svg') no-repeat;
    width: 20px;
    height: 20px;
    display: inline-block;
    display: inline-block;
    background-position-x: 4px;
    background-position-y: 5px;
    margin-right: 3px;
}*/

    .added-button::after {
        content: "";
        background: url('../../../images/dark-blue-check-icon.svg') no-repeat;
        width: 20px;
        height: 20px;
        display: inline-block;
        display: inline-block;
        background-position-x: 4px;
        background-position-y: 5px;
        margin-left: 4px;
    }

.add-button:focus, .add-button:active {
    border-color: #006fad;
    outline: none;
}

.added-button:focus, .added-button:active, .added-button:hover {
    border-color: #303c55;
    outline: none;
}