.images-slider-wrap {
	position: fixed;
	z-index: 20;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow: hidden;
	background-color: rgba(0,0,0,0.5);
}

.images-slider {
	position: relative;
	width: 100%;
	height: calc(100% - 1px);
	margin-top: 1px;
	background-color: #fff;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
}

.is-main-image-wrap {
	margin: 20px 0 20px 100px;
	height: calc(100vh - 40px);
	width: calc(100% - 330px);
	display: flex;
	justify-content: center;
	align-items: center;
}

.is-main-image {
	height: 100%;
	width: 100%;
	position: relative;
	overflow: hidden;
	text-align: center;
	border-radius: 10px;
	max-width: 1920px;
}

.is-main-image-img {
	object-fit: cover;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	opacity: 0;
	transition: opacity 0.5s ease-in-out;
	border-radius: 10px;
}

	.is-main-image-img.active {
		opacity: 1;
	}

.is-arrow {
	position: absolute;
	top: calc(50% - 21px);
	background: #fff;
	z-index: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

	.is-arrow.is-left-arrow {
		left: 0;
		border-top-right-radius: 50%;
		border-bottom-right-radius: 50%;
	}

	.is-arrow.is-right-arrow {
		right: 0;
		border-top-left-radius: 50%;
		border-bottom-left-radius: 50%;
	}

		.is-arrow.is-right-arrow .is-arrow-icon {
			transform: rotate(180deg);
		}

.is-arrow-icon {
	height: 100%;
}

.close-images-slider {
	position: absolute;
	right: 10px;
	top: 10px;
	z-index: 21;
}

.is-image-thumbnails-wrap {
	margin: 60px 30px 15px 0;
	width: 200px;
	height: calc(100vh - 80px);
	overflow: hidden;
	user-select: none;
	position: relative;
}

.is-image-thumbnails {
	text-align: right;
	user-select: none;
	position: absolute;
	left: 0;
}

.is-thumbnail {
	height: 92px;
	margin-bottom: 8px;
	border-radius: 10px;
	cursor: pointer;
	user-select: none;
}

@media (max-width: 1000px) {
	.is-main-image-wrap {
		margin: 60px 20px;
		width: calc(100% - 40px);
		height: calc(100vh - 120px);
	}

	.is-image-thumbnails-wrap {
		display: none;
	}

	.images-slider-wrap {
		height: 100vh;
	}

	.images-slider {
		margin-top: 0;
		height: 100%;
	}
}

@media (max-width: 1000px) and (min-width: 601px) and (orientation: landscape) {
	.is-main-image-wrap {
		margin: 20px;
		height: calc(100vh - 40px);
	}
}

@media (max-width: 767px) {
	.is-arrow {
		height: 32px;
		top: calc(50% - 16px);
	}
}

@media (max-width: 600px) {
	.images-slider-wrap:not(.no-fullscreen-slider) {
		height: 100vh;
		background: #fff;
		overflow: hidden;
	}

		.images-slider-wrap:not(.no-fullscreen-slider) .images-slider {
			transform: rotate(90deg) translateY(-100%);
			transform-origin: top left;
			height: 100vw;
			width: 100vh;
			overflow: hidden;
		}

		.images-slider-wrap:not(.no-fullscreen-slider) .is-main-image-wrap {
			width: 100%;
			height: 100%;
			margin: 0;
			padding: 20px;
			overflow: hidden;
		}

		.images-slider-wrap:not(.no-fullscreen-slider) .is-main-image {
			border-radius: 0;
			overflow: hidden;
		}

		.images-slider-wrap:not(.no-fullscreen-slider) .is-main-image-img {
			max-width: 100%;
			border-radius: 0;
		}
}
