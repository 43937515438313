.primaryButton {
    font-size: 17px !important;
    color: rgba(255 255 255);
    font-family: Domus !important;
    font-weight: 600 !important;
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
    background-color: rgba(34 43 63);
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width:100%;
    height:52px;
    display:flex;
    border:none !important;
}
