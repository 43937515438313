.display {
	display: flex;
}

.information {
	padding: 30px;
	border-radius: 5px;
	background-color: #f0f8fd;
	border-radius: 6px;
	border: 1px solid #C9E7F9;
}

.firstname {
	padding-right: 10px;
}

.adult-error {
	color: red
}

.lead-checkbox {
	top: 2px !important;
}

.country-code-input {
	width: 40%;
	margin-right: 10px;
	border-radius: 26px;
}

.phone-number-input {
	width: 60%;
}

.menu-flags-button {
	padding: 0px 10px !important;
	color: #C0C0C0 !important;
	border: solid 1px #5e5e5e !important;
	outline: none !important;
	font-size: 1.05rem !important;
	font-family: Domus !important;
	font-weight: 400 !important;
}

	.menu-flags-button span {
		padding: 1px 0 1px 1px !important;
	}

	.menu-flags-button:focus {
		color: #006da8 !important;
		outline: 5px auto #006da8;
	}

.ReactFlagsSelect-module_selectFlag__2q5gC {
	font-size: 2.0em !important;
}

@media (max-width: 1024px) {
	.ReactFlagsSelect-module_flagsSelect__2pfa2 {
		padding-bottom: 0 !important;
	}

	.firstname {
		padding-right: 0px;
	}

	.display {
		display: block;
	}

	.card {
		padding: 15px;
	}

	.information {
		padding: 15px;
	}

	.country-code-input {
		width: 100%;
		margin-right: 0;
	}

	.phone-number-input {
		width: 100%;
	}
}

#rfs-btn {
	border-radius: 26px;
	height: 52px;
}

.ReactFlagsSelect-module_selectValue__152eS {
	margin-left: 15px !important;
}
