
.error-message {
	background-color: #f54040;
	padding: 12px 19px 18px 10px;
	border-radius: 5px;
	color: #fff;
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}

	.error-message.success {
		background-color: #28a745;
	}

.em-icon-col {
	width: 20px;
}

.em-icon {
	width: 100%;
}

.em-text-col {
	width: calc(100% - 34px);
}