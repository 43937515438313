
@media (max-width: 767px) {
	.mvp-info-wrap {
		width: calc(100% + 24px);
		transform: translateX(-12px);
	}

	.mvp-info-wrap > .card.grey-colour {
		border-radius: 0;
		width: 100%;
	}
}

.transfers-information {
	padding: 20px;
	border-radius: 1px;
	background-color: #f0f8fd;
	border-color: #BFE6FF;
	border-style: solid;
}

.transfers-information p{
	margin-bottom: unset;
}

/*START AB CTA*/
.bottom-navigation {
	box-shadow: 0 1px 3px 0 rgba(59, 64, 67, 0.13), 0 0 8px 3px rgba(59, 64, 67, 0.07);
	background-color: #fff;
	width: 100%;
	position: fixed;
	margin-left: -10px;
	z-index: 999;
	height: 81px;
}
.btn:focus {
	box-shadow: none;
}
/*END AB CTA*/


