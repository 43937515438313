body div {
	margin: 0;
}

.payment-total {
	margin-bottom: 1rem;
}

.payment-total-price {
	font-size: 1.75rem;
	font-weight: 700;
	line-height: 2rem;
}
.payment-discounted-price-available {
	color: #767676 !important;
	font-weight: normal !important;
	font-size: 24px !important;
/*	text-decoration: line-through;*/
}
.payment-total-price-strike-through {
	position: absolute;
	height: 1px;
	width: 71px;
	background-color: #898989;
	top: 44px;
	transform: rotate(-17deg);
	margin: auto;
}

.payment-option {
	margin-left: 5%;
	cursor: pointer;
	padding: 0px;
}

.option {
	font-family: Domus;
	padding: .50rem;
	justify-content: center;
	align-items: center;
	display: flex;
	flex-direction: column;
}

#payment-form .user-consent {
	padding: 0;
	margin-top: 0.5rem;
	margin-bottom: 0;
}

#vp-payment-gateway *:not(.fa) {
	font-family: inherit !important;
	font-size: inherit !important;
}

#vp-payment-gateway {
	margin-top: 30px;
}

#payment-form.container {
	padding: 0;
	max-width: none;
}

.transaction-secured {
	font-size: 0.9375rem;
	font-weight: 300;
	text-align: center;
	margin: 0.8rem 0 0;
}

.vp-input-div > .row > img {
	padding-left: 3px;
}

#payment-form #vp-pay-btn-div {
	text-align: center;
	padding-top: 3rem;
}

#payment-form #pay-btn {
	background-color: rgba(34 43 63);
	background-image: none;
	color: #fff;
	height: 52px;
	width: auto;
	border-radius: 10px;
	min-width: 280px;
	max-width: 100%;
	margin: 0 auto;
	font-size: 17px;
	font-family: 'Domus';
	font-weight: 600;
}

#payment-form #continue-btn {
	background-color: #303c55;
	background-image: none;
	color: #fff;
	height: 52px;
	border-radius: 10px;
	width: auto;
	min-width: 280px;
	max-width: 100%;
	margin: 0 auto;
	font-size: 17px;
	font-family: 'Domus';
	font-weight: 600;
}

#payment-form #continue-btn:focus, #payment-form #pay-btn:focus {
	box-shadow: none;
}

.toggle-voucher-button {
	text-decoration: underline;
}

.submit-voucher-button {
	position: absolute;
	top: 0;
	right: 0;
	height: 100%;
}

.voucher-box-wrap .input-field {
	padding-right: 75px;
}

.submit-voucher-button.btn-transparent {
	padding: 0.5rem;
}

@media (max-width: 359px) {
	.submit-voucher-arrow {
		display: none;
	}
}

@media (max-width: 767px) {
	.card.checkout-card {
		margin-top: -10px;
		padding: 0;
		overflow: hidden;
	}

		.card.checkout-card .card-body {
			padding: 0;
		}

	.payment-total {
		margin-bottom:0px !important;
	}

	.payment-total-mobile {
		font-size: 0.875rem;
		margin-left: 5px;
	}

	.cost-details-heading {
		background-color: #f0f8fd;
		padding: 0.75rem 1rem;
	}

	.payment-details-wrap {
		padding: 0.75rem 1rem;
		width: 100%;
	}

	#vp-payment-gateway {
		margin-top: 0;
	}

	.transaction-secured {
		display: none;
	}

	#payment-form #vp-pay-btn-div {
		padding-top: 0;
	}

	#payment-form #pay-btn {
		margin-bottom: 1.2rem;
		width: 100%;
	}

	#payment-form .user-consent {
		margin-top: 1.2rem;
		margin-bottom: 1.2rem;
	}

	.payment-total-price {
		font-weight: 600;
	}
}
