.placeholder-wrapper {
	padding-left: 25px;
	padding-right: 12px;
	padding-top: 15px;
	pointer-events: none;
	position: absolute;
	left: 0;
	top: 0;
	transition: 0.2s;
	transition-timing-function: ease;
	transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
	opacity: 0.5;
	color: #615e5e;
}

.input-field {
	width: 100%;
	height: 52px;
	padding-left: 25px;
	border-radius: 26px;
	border: solid 1px #5e5e5e;
	background-color: #fff;
}

	.input-field:focus + .placeholder-wrapper, .input-field:not(:placeholder-shown) + .placeholder-wrapper {
		opacity: 1;
		transform: scale(0.75) translateY(-85%) translateX(-10px);
		z-index: 999;
		background: #fff;
		color: #006da8;
		margin-left: 15px;
		padding-left: 12px;
	}

	.input-field + .placeholder-wrapper, .input-field:not(:placeholder-shown) + .placeholder-wrapper {
		color: #615e5e;
	}

	.input-field:focus {
		border: solid 2px #006da8 !important;
		padding-left: 25px;
		outline: none;
	}

		.input-field:focus + .placeholder-wrapper {
			color: #006da8;
		}

	.input-field.show-validation + .placeholder-wrapper {
		color: #FF0000;
		opacity: 1;
	}

	.input-field.show-validation + .placeholder-wrapper {
		background-image: url(../../../images/validation-icon.svg);
		background-size: 14px;
		background-position: 3% 55%;
		background-repeat: no-repeat;
		padding-left: 30px;
		margin-left: 0;
	}

	.input-field.show-validation {
		border: solid 1px #FF0000 !important;
	}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type=number] {
	-moz-appearance: textfield;
}



@media (max-width: 767px) {
	.input-field {
		width: 100%;
	}

	.placeholder-wrapper {
		padding-left: 25px;
		padding-right: 12px;
		padding-top: 12px;
	}

	.input-field:focus + .placeholder-wrapper, .input-field:not(:placeholder-shown) + .placeholder-wrapper {
		opacity: 1;
		transform: scale(0.75) translateY(-85%) translateX(-10px);
		z-index: 999;
		background: #fff;
		color: #006da8;
		margin-left: 15px;
		padding-left: 12px;
	}
}
