.checkbox-container input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}

.checkmark {
	position: absolute;
	top: 0;
	left: 10px;
	height: 22px;
	width: 20px;
	border-radius: 1px;
	border: solid 2px #444;
}

.checkbox-container input:checked ~ .checkmark {
	background-color: #006da8;
	border-radius: 1px;
	border: solid 2px #006da8;
}

.checkmark:after {
	content: "";
	position: absolute;
	display: none;
}

.checkbox-container input:checked ~ .checkmark:after {
	display: block;
}

.checkbox-container .checkmark:after {
	left: 5px;
	top: 0px;
	width: 8px;
	height: 15px;
	border: solid white;
	border-width: 0 3px 3px 0;
	-webkit-transform: rotate(35deg);
	-ms-transform: rotate(35deg);
	transform: rotate(35deg);
}
