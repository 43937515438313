html {
	scroll-behavior: smooth;
}

body {
	margin: 0;
	padding: 0;
	font-family: Domus;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.5;
	letter-spacing: normal;
	color: #111;
	font-size: 1.06rem;
	overflow-x: hidden;
	overflow-y: auto;
}

#root {
	background-color: rgba(174, 165, 153, 0.1);
	overflow-x: hidden;
}

.card {
	border-radius: 10px;
	box-shadow: 0 0 0.5px 0 rgba(0, 0, 0, 0.28);
	padding: 1rem;
	margin-bottom: 1.5rem;
}

	.card.colored-bottom {
		border-bottom: none;
		background: linear-gradient(#96d1f2,#96d1f2) bottom no-repeat;
		background-size: 80% 1.5px;
		background-color: #fff;
	}

ul.dot-list {
	padding: 0;
	margin: 0;
}

	ul.dot-list > li {
		list-style: none;
		margin-bottom: 0.2rem;
		font-weight: 300;
		font-size: 19px;
	}

		ul.dot-list > li:before {
			content: "\2022";
			padding: 0 1.2rem 0 0.1rem;
		}

.check-list {
	padding: 0;
	margin: 0;
}

	.check-list > li {
		list-style: none;
		margin: 0.5rem 0;
	}

		.check-list > li:before {
			content: "";
			background-image: url(./images/blue-check-icon.svg);
			background-size: 17px;
			background-position: left center;
			background-repeat: no-repeat;
			padding-left: 2rem;
			margin-left: 0.3rem;
		}

.bs-text {
	font-weight: 600;
	padding-left: 0.3rem;
}

.font-size-14 {
	font-size: 0.875rem;
}

.font-size-15 {
	font-size: 0.9375rem;
}

.font-size-16 {
	font-size: 1rem;
}

.font-size-19 {
	font-size: 1.188rem;
}

.font-size-22 {
	font-size: 1.375rem;
}

.font-size-38 {
	font-size: 2.375rem;
}

.font-light {
	font-weight: 300;
}

.font-normal {
	font-weight: 400;
}

.font-semibold {
	font-weight: 600;
}

.font-bold {
	font-weight: 700;
}

.grey-colour {
	background-color: #ebebe8;
}

.card.grey-colour {
	border: none;
	border-radius: 5px;
	padding: 0.25rem 1rem;
}

.close-button {
	width: 41px;
	cursor: pointer;
	opacity: 0.5;
}

	.close-button:hover {
		opacity: 0.75;
	}

.content-container {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	padding: 30px 11px;
	margin: 0 auto;
	width: 100%;
	max-width: 1130px;
}

.secure-pay-disclaimer-container {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	padding: 10px 11px;
	margin: 0 auto;
	width: 100%;
	max-width: 1130px;
}

.card-title {
	font-weight: 600;
	font-size: 17px;
}

.card-title::first-letter {
	text-transform: uppercase;
}

.pointer {
	cursor: pointer;
}

.btn {
	font-size: 1.06rem;
	padding: 0.5rem 1.5rem;
}

.btn-primary {
	color: #fff;
	background-color: #303c55;
	border: none;
}

.btn-primary:hover, .btn-primary:active, .btn-primary:focus {
	background-color: #303c55 !important;
	box-shadow: none;
	border-color: #303c55 !important;
}

.btn-primary:active:focus {
	box-shadow: none !important;
}


.btn-directional-next, .btn-directional-next:hover, .btn-directional-next:active, .btn-directional-next:focus, .btn-directional-next:active:focus {
	background-color: #303c55;
	border: none;
	border-radius: 10px;
	font-size: 17px !important;
	color: rgba(255 255 255);
	font-family: Domus !important;
	font-weight: 600 !important;
	box-shadow: none !important;
	padding-left: 20px;
	padding-right: 20px;
	height: 52px;
	align-items: center;
	display: flex;
	justify-content: center;
}


.btn-transparent {
	background-color: rgba(0,0,0,0);
}

.btn-transparent:hover {
	background-color: rgba(0,0,0,0.1);
}

.btn-directional-back {
	background-color: rgba(0,0,0,0);
	font-size: 17px !important;
	color: #111;
	font-family: Domus !important;
	font-weight: 400 !important;
	padding-left: 20px;
	padding-right: 20px;
	height: 44px;
	align-items: center;
	display: flex;
	justify-content: center;
}

	.btn-directional-back:hover {
		background-color: rgba(0,0,0,0.1);
		border-radius:3px;
	}

.confirmation-content {
	background-color: #fff;
}

@media (max-width: 767px) {
	body {
		font-size: 1rem;
	}

	.content-container {
		padding: 0 11px;
	}

	.card {
		padding: 0.5rem 0.75rem;
	}
	.mobile-padding-top-20 {
		padding-top: 20px;
	}
}

.checkMarker {
	margin-right: 9px;
	margin-top: 7px;
}

.icon-checkMarker {
	width: 17px;
	height: 12px;
	min-width: 17px;
	background: url(./images/DarkBlueChecklistIcon.svg) no-repeat;
}

#vp-payment-gateway .loader {
	background: url(./images/spinner.svg);
	border: none;
}

#applied-voucher {
	height: 73px;
	border-radius: 10px;
	border: solid 1px #e4e3e3;
	background-color: #f6f6f4;
}

