.bc-header-wrap {
	background-color: #fff;
}

.bc-header {
	padding-bottom: 0;
}

.bc-title {
	display: flex;
	justify-content: start;
	align-items: center;
}

.bc-title-text {
	display: inline-block;
	font-weight: 700;
	font-size: 2.375rem;
	margin-right: 15px;
	line-height: 1.2;
}

.bc-blue-check {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	height: 28px;
	width: 28px;
	border: 2px solid #006da8;
	border-radius: 50%;
	margin-top: 9px;
}

.bc-blue-check-icon {
	width: 16px;
}

.bc-booking-ref {
	margin-top: 8px;
	line-height: 1;
	font-size: 1.1875rem;
	font-weight: 600;
}

.bc-mvp-info {
	margin-bottom: 12px;
}

.bc-mvp-info-text {
	display: inline-block;
	margin-right: 14px;
	margin-bottom: 16px;
	font-weight: 300;
}

.bc-mvp-link {
	border: 2px solid #2d4a60;
	border-radius: 10px;
	padding-left: 15px;
	padding-right: 15px;
	display: inline-flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 4px;
}

.bc-mvp-link-icon {
	margin-right: 8px;
}

.bc-mvp-link-text {
	font-weight: 600;
	font-size: 1.0625rem;
	color: #2d4a60;
}

@media (max-width: 767px) {
	.bc-title {
		margin-top: 8px;
	}

	.bc-title-text {
		font-size: 1.5rem;
		margin-right: 11px;
	}

	.bc-blue-check {
		margin-top: 2px;
	}

	.bc-mvp-info {
		margin-bottom: 20px;
	}

	.bc-mvp-info-text {
		display: inline-block;
		margin: 10px 0 20px;
	}
}