.radio-input-wrap {
	display: flex;
	justify-content: flex-start;
	cursor: pointer;
}

.radio-input {
	margin: 0 10px 0 0;
	width: 20px;
	height: 20px;
	border: 1px solid #444;
	border-radius: 50%;
	position: relative;
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

	.radio-input.selected {
		border-color: #006da8;
	}

.ri-selected-dot {
	background-color: #006da8 ;
	width: 10px;
	height: 10px;
	margin: 0;
	opacity: 0;
	border-radius: 50%;
}

.radio-input.selected .ri-selected-dot {
	opacity: 1;
}

.ri-hidden-input {
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	opacity: 0;
}

.ri-label {
	margin: -4px 0 0;
}