.content-col-l {
	width: calc(58% - 16px);
}

.content-col-r {
	width: calc(42% - 16px);
}

@media (max-width: 767px) {
	.content-container {
		flex-direction: column-reverse;
		padding: 0 11px 15px;
	}

	.content-col-l {
		width: 100%;
	}

	.content-col-r {
		width: 100%;
	}

	.card-body {
		padding: 0.25rem;
	}
}