#errorModal.modal {
    display: flex;
    background-color: rgba(0, 0, 0, 0.53);
    align-items: center;
}

#errorModal .modal-header{
    font-weight: 600;
    border-bottom: unset;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 2rem;
    padding-bottom: 0;
}

#errorModal .modal-body {
    border-bottom: unset;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

#errorModal .modal-header .close{
    padding: unset;
}