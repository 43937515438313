.checkout-mobile-heading {
	display: none;
}

@media (max-width: 767px) {
	.checkout-mobile-heading {
		display: block;
	}

	.cmh-image-wrap {
		width: calc(100% + 22px);
		margin: 0 -11px;
		height: 168px;
		position: relative;
		overflow: hidden;
	}

	.cmh-image {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 100%;
	}
}