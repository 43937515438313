.show-modal {
	display: block !important;
	background-color: rgba(0, 0, 0, 0.53);
}
.text-left{
	text-align:left !important;
}
.vertical-line {
	border-right: solid 2px #e9e9e9;
	margin-right: 15px;
	margin-left: 15px;
}
.modal-border {
	border-radius: 10px;
}

.modal-button {
	border-radius: 5px;
	border: solid 2px #2d4a60;
	background-color: #fff;
	color: #006da8;
	width: 138px;
}
.modal-max-size {
	max-width: 100vw;
	width:1000px !important;
}
.modal-title {
	font-size: 19px;
}
.selected {
	background-color: #C9E7F9;
	border-radius: 19px;
	font-weight: 600 !important;
}
.nav-link.custom-tab.active {
	border-bottom: solid 3px #006da8 !important;
	background-color: transparent !important;
	border-top-color: transparent !important;
	border-left-color: transparent !important;
	border-right-color: transparent !important;
	font-weight: 600 !important;
}
.nav-tabs > li {
	float: none;
	display: inline-block;
	zoom: 1;
}
.nav-tabs {
	text-align: center;
}
.margin-10 {
	margin-left: 0.625rem;
	margin-right: 0.625rem;
}
.margin-top-19 {
	margin-top: 1.188rem;
}
.margin-left-30 {
	margin-left: 1.875rem;
}
.width-225 {
	width: 225px;
}
.padding-left-5 {
	padding-left: 15px;
}
.padding-right-7 {
	padding-right: 7px;
}
.font-size-17 {
	font-size: 17px;
}
.font-size-19 {
	font-size: 19px;
}
.font-weight-600 {
	font-weight: 600
}
.font-weight-400 {
	font-weight: 400
}
.padding-left-50 {
	padding: 0.5rem 1rem !important;
}

.lc-language-currency-container {
	border-bottom: 1px solid #dee2e6;
}


@media(min-width: 768px) {
	.lc-language-title {
		padding-left: 20px;
		padding-bottom: 16px;
		font-size: 1.1875rem;
		font-weight: 600;
	}

	.lc-currency-title {
		padding-left: 20px;
		padding-bottom: 16px;
		font-size: 1.1875rem;
		font-weight: 600;
	}
	.lc-language-content {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
	}
	
	.lc-option{
		height: 41.5px;
		min-width: 135px;
		max-width: 210px;
	}
}
@media(max-width: 767px) {
	.tab-content {
		overflow-y: scroll;
		height: 490px;
	}
	.lc-option {
		width: 50px;
		height: 41.5px;
	}
	.modal-max-size{
		width:auto !important;
	}
}
.modal-close-button {
	position: absolute;
	right: 0;
	z-index: 9999;
}

.button-disabled {
	cursor: not-allowed;
}