header {
	width: 100%;
	background-color: #fff;
	padding-top: 17px;
	padding-bottom: 17px;
}

.headerRow {
	height:50px;
}

.header-logo {
	width: 160px;
	margin-left: 20px;
}

.established-logo{
	width:182px;
}

.breadcrumb-trail {
	display: flex;
	justify-content: center;
	align-items: center;
}

.page-title {
	font-size: 1.75rem;
	font-weight: 600
}

.bt-steps-wrap {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: nowrap;
}

.bt-step {
	width: 24px;
	height: 24px;
	font-size: 0.875rem;
	font-weight: 400;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #615e5e;
	background-color: #f6f6f4;
	border-radius: 50%;
	padding: 0;
	position: relative;
	margin-right: 17px;
	padding-bottom: 4px;
}

	.bt-step.active {
		font-size: 1rem;
		font-weight: 700;
		background-color: #222B3F;
		color: #fff;
	}

	.bt-step::after {
		content: '';
		width: 17px;
		height: 5px;
		background-color: #f6f6f4;
		position: absolute;
		top: calc(50% - 2.5px);
		left: 100%;
	}

	.bt-step:last-child::after {
		content: none;
		margin-right: 0;
	}

.header-dark-blue {
	color: #2d4a60;
}

.h-faqs-icon {
	color: #007ad4;
	font-weight: 700;
	height: 18px;
	width: 18px;
	border: 2px solid #2d4a60;
	border-radius: 50%;
	margin-left: 8px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 3px;
}

.abta-logo {
	width: 62px;
	margin-left: 2rem;
	margin-right: 1rem;
}

@media (min-width: 768px) {
	.header-logo-wrap {
		width: 235px;
	}
}

@media (max-width: 767px) {
	.breadcrumb-trail {
		flex-direction: column;
	}

	.page-title {
		font-size: 1.06rem;
	}

	.header-logo {
		width: 112px !important;
		margin-left: 4px !important;
	}
	header {
		padding-top: 11px !important;
		padding-bottom: 11px !important;
	}
	.header-logo-wrap {
		margin-top: -5px !important;
	}
	.breadcrumb-trail {
		margin-bottom: 2px !important;
	}
}

@media (min-width: 768px) and (max-width: 992px) {
	header {
		padding-top: 11px !important;
		padding-bottom: 11px !important;
	}
	.header-logo {
		width: 112px !important;
		margin-left: 4px !important;
	}
	.header-logo-wrap {
		margin-top: -3px !important;
	}
}

	@media (max-width: 800px) and (min-width: 768px) {
		.header-logo {
			margin-left: 0px !important;
		}
	}