
.full-screen-loading {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 99;
	background-color: rgba(255, 255, 255, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
}

.fsl-image {
	width: 50%;
	max-width: 200px;
	height: 10%;
}
