.booking-summary.card {
	padding: 0;
}

.summary-heading {
	height: 60px;
	font-weight: 600;
	display: flex;
	justify-content: center;
	align-items: center;
}

.thumbnail-image {
	width: calc(50% - 0.5px);
	cursor: pointer;
}

.summary-text-section {
	padding-bottom: 11px;
	margin: 0 1rem 1rem;
}
.summary-text-section-border {
	border-bottom: 1px solid #ededed;
}

	.summary-text-section p {
		line-height: 14px;
	}

.ai-villa-name {
	font-size: 1.75rem;
	font-weight: 700;
	margin-bottom:0px !important;
}

.pb-total-price {
	font-size: 24px;
	font-weight: 600;
}

.pb-total-price-number{
	font-size: 2rem;
	font-weight: 800;
}

.il-title {
	font-weight: 600;
}

.mobile-summary {
	cursor: pointer;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.ms-villa-name {
	font-weight: 600;
}

.ms-dates {
	font-size: 0.9rem;
	font-weight: 300;
}

.ms-basket-icon {
	width: 32px;
	height: auto;
	margin-right: 11px;
	margin-left: 5px;
}

.ms-bd-price {
	font-size: 1.1rem;
	font-weight: 600;
}

.ms-bd-summary {
	font-size: 0.9rem;
	color: #006da8;
}

.ms-basket-description {
	display: flex;
	flex-direction: column;
	align-content: flex-start;
	justify-content: center;
	padding-bottom: 3px;
}

.close-mobile-summary {
	position: absolute;
	top: calc(50% - 23px);
	right: 5px;
}

.loading-spinner {
	width: 30%;
}

@media (max-width: 767px) {
	.booking-summary-wrap {
		visibility: hidden;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		padding: 11px 0;
		opacity: 0;
		transition: opacity 0.5s ease-in-out, visibility 0.5s;
		overflow-y: auto;
	}

	.booking-summary-wrap.active {
		visibility: visible;
		background-color: rgba(0,0,0,0.5);
		opacity: 1;
		z-index: 999;
		overflow-y: auto;
	}

	.booking-summary {
		transform: translateY(100%);
		transition: transform 0.5s ease-in-out;
	}

	.booking-summary-wrap.active .booking-summary {
		transform: translateY(0);
	}

	.summary-heading {
		position: relative;
	}
}
@media (max-width: 480px) {
	.accommodation-images {
		max-height: 170px;
	}
}

@media (max-width: 600px) {
	.booking-summary-wrap {
		padding: 0;
		overflow: hidden;
	}
}

.next-button-container{
	margin-left: 1rem;
	margin-right: 1rem;
}

#next-button{
	display: none;
}

/*START AB CTA*/
.summary-button-mobile {
	border-radius: 10px;
	border: solid 1px #111;
	background-color: #fff;
	width: 120px;
	height: 40px;
}

.summary-button-mobile-text {
	line-height: 1.5;
	padding-bottom: 2px;
}

.summary-button-mobile-image {
	margin-left: -5px;
}

.bottom-price-line-height {
	line-height: 1;
}
/*END AB CTA*/
