footer {
	width: 100%;
	background-color: #fff;
}

.link {
	color: #111;
	cursor: pointer;
	text-decoration: none;
}

.no-list-style {
	list-style: none;
}

.pay-secure-cards {
	max-width: 270px;
}

ul.dot-list.footer-links {
	flex-wrap: wrap;
}

	ul.dot-list.footer-links > li {
		margin-right: 1.5rem;
	}

		ul.dot-list.footer-links > li:first-child::before {
			content: none;
		}

@media (max-width: 767px) {
	ul.dot-list.footer-links > li::before {
		content: none;
	}

	ul.dot-list.footer-links > li {
		margin-right: 0;
		margin-bottom: 1.25rem;
	}
}

.ab-bottom-padding {
	padding-bottom: 50px;
}
