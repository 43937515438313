/*.form-control {
    border-radius: 26px!important;
}*/

.remove-card {
    border: none!important;
}

.add-card {
    background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2220%22%20height%3D%2220%22%20viewBox%3D%220%200%2020%2020%22%20fill%3D%22none%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%0A%3Cg%20id%3D%22Add%22%20clip-path%3D%22url%28%23clip0_3156_131%29%22%3E%0A%3Cpath%20id%3D%22Icon%22%20d%3D%22M15.7763%209.99967C15.7763%2010.3897%2015.4604%2010.7056%2015.0704%2010.7056H10.7076V15.0683C10.7076%2015.4584%2010.3917%2015.7743%2010.0017%2015.7743C9.61165%2015.7743%209.29574%2015.4584%209.29574%2015.0683V10.7056H4.93301C4.54298%2010.7056%204.22707%2010.3897%204.22707%209.99967C4.22707%209.60964%204.54298%209.29373%204.93301%209.29373H9.29574V4.93101C9.29574%204.54097%209.61165%204.22506%2010.0017%204.22506C10.3917%204.22506%2010.7076%204.54097%2010.7076%204.93101V9.29373H15.0704C15.4604%209.29373%2015.7763%209.60964%2015.7763%209.99967ZM19.1666%209.99967C19.1666%2015.0542%2015.0545%2019.1663%209.99992%2019.1663C4.94537%2019.1663%200.833252%2015.0542%200.833252%209.99967C0.833252%204.94512%204.94537%200.833008%209.99992%200.833008C15.0545%200.833008%2019.1666%204.94512%2019.1666%209.99967ZM17.7547%209.99967C17.7547%205.72343%2014.2762%202.24489%209.99992%202.24489C5.72367%202.24489%202.24514%205.72343%202.24514%209.99967C2.24514%2014.2759%205.72367%2017.7545%209.99992%2017.7545C14.2762%2017.7545%2017.7547%2014.2759%2017.7547%209.99967Z%22%20fill%3D%22%23006FAD%22/%3E%0A%3C/g%3E%0A%3Cdefs%3E%0A%3CclipPath%20id%3D%22clip0_3156_131%22%3E%0A%3Crect%20width%3D%2220%22%20height%3D%2220%22%20fill%3D%22white%22/%3E%0A%3C/clipPath%3E%0A%3C/defs%3E%0A%3C/svg%3E%0A")!important;
    background-position-y: 12px!important;
}

.remove-card {
    background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2220%22%20height%3D%2221%22%20viewBox%3D%220%200%2020%2021%22%20fill%3D%22none%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%0A%3Cg%20id%3D%22Minus%22%3E%0A%3Cpath%20id%3D%22Icon%22%20d%3D%22M15.0704%2010.7056H4.93133C4.81744%2010.7056%204.72538%2010.6136%204.72538%2010.4997C4.72538%2010.3858%204.81743%2010.2937%204.93133%2010.2937H15.0704C15.1843%2010.2937%2015.2764%2010.3858%2015.2764%2010.4997C15.2764%2010.6136%2015.1843%2010.7056%2015.0704%2010.7056ZM9.99999%201.83301C14.7784%201.83301%2018.6667%205.72127%2018.6667%2010.4997C18.6667%2015.2781%2014.7784%2019.1663%209.99999%2019.1663C5.22159%2019.1663%201.33333%2015.2781%201.33333%2010.4997C1.33333%205.72127%205.22159%201.83301%209.99999%201.83301ZM9.99999%2018.7545C14.5524%2018.7545%2018.2548%2015.0521%2018.2548%2010.4997C18.2548%205.94728%2014.5524%202.24489%209.99999%202.24489C5.4476%202.24489%201.74521%205.94728%201.74521%2010.4997C1.74521%2015.0521%205.4476%2018.7545%209.99999%2018.7545Z%22%20fill%3D%22%23006FAD%22%20stroke%3D%22%23006FAD%22/%3E%0A%3C/g%3E%0A%3C/svg%3E%0A") !important;
    background-position-y: 12px !important;
}
