

.vertical-align-bottom {
	vertical-align: bottom;
}


.ab-extras-collapsible-container .Collapsible__contentInner {
	margin-left: 5px;
	margin-bottom: -20px;
}


.ab-ec-title-container span{
	font-family: Domus;
	font-weight: 300;
	margin-top: -6px;
}

.ab-extras-content-wrapper .ab-extras-card-wrapper {
	flex: 0 0 100%;
	max-width: 100%;
}

.ab-extras-content-wrapper {
	margin-bottom: 4px;
}

.ab-extras-card {
	width: 100%;
	height: 100%;
	padding: 22px 26px;
	border-radius: 10px;
}

.ab-extras-card-active {
	background-color: #c9e7f9;
}

.ab-extras-card-title {
	font-family: Domus;
	font-size: 17px;
	font-weight: normal;
	color: #111;
}

.ab-extras-card-price {
	font-family: Domus;
	font-size: 19px;
	font-weight: 600;
	color: #111;
}

.ab-extras-card-image {
	width: 100%;
	height: 144px;
	object-fit: contain;
}

.ab-add-btn {
	font-family: Domus;
	font-size: 16px;
	font-weight: 600;
	color: #111;
	padding: 7px 23px;
	border-radius: 10px;
	background-color: #fff;
	height: 42px;
}

.ab-add-btn:focus, .ab-add-btn:active {
	outline: none;
}

.ab-added-btn {
	border-radius: 10px;
	border: solid 1px #303c55;
	font-family: Domus;
	font-size: 16px;
	font-weight: 600;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 42px;
	padding: 8px 12px 8px 14px;
}


.ab-added-btn::after {
	content: "";
	background: url('../../../images/dark-blue-check-icon.svg') no-repeat;
	width: 20px;
	height: 20px;
	display: inline-block;
	display: inline-block;
	background-position-x: 4px;
	background-position-y: 5px;
	margin-left: 4px;
}


.ab-added-btn:disabled {
	border-color: #303c55;
	cursor: not-allowed;
}

.ab-added-btn:focus, .ab-added-btn:active, .ab-added-btn:hover {
	outline: none;
}

.ab-quantity-selector {
	outline:none;
	background-color: transparent;
	border: none;
	padding: 0;
}

.ab-quantity-selector:disabled {
	cursor: not-allowed;
	opacity: 0.33;
}

.ab-quantity-selector:active, .ab-quantity-selector:focus {
	outline: none;
}

.ab-quantity {
	margin-left: 9.5px;
	margin-right: 9.5px;
}

@media (max-width: 480px) {
	.ab-extras-collapsible-container {
		padding: 0 !important;
	}

	.ab-extras-collapsible-container .Collapsible__contentInner {
		margin-left: 0;
		margin-bottom: -20px;
	}

	.ab-extras-card-body {
		padding: 0;
		padding-bottom: 24px;
	}

	.ab-extras-collapsed-container {
		padding-top: 24px;
		padding-left: 20px;
		padding-right: 20px;
	}

	.ab-extras-content-wrapper {
		margin-left: 0;
		margin-right: 0;
		margin-bottom: 4px;
	}

	.ab-extras-content-wrapper .ab-extras-card-wrapper {
		padding-left: 0;
		padding-right: 0;
	}
	
}

@media (min-width: 480px) {
	.ab-extras-content-wrapper .ab-extras-card-wrapper {
		flex: 0 0 50%;
		max-width: 50%;
	}
}

@media (min-width: 768px) and (max-width: 992px) {
	.ab-extras-content-wrapper .ab-extras-card-wrapper {
		flex: 0 0 100%;
		max-width: 100%;
	}
}

[role='tooltip'].popup-content {
	width: 440px;
}

@media (max-width: 992px) {
	[role='tooltip'].popup-content {
		width: 400px;
	}
}


@media (max-width: 539px)  {
	[role='tooltip'].popup-content {
		width: 280px;
	}
}

@media (max-width: 405px) {
	[role='tooltip'].popup-content {
		width: 220px;
	}
}